import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StartSteps } from '../pages/HomePage'
import Button from './Button'
import LinkField from './LinkField'
import Popup from './Popup'

// See common getUserMedia errors:
// https://blog.addpipe.com/common-getusermedia-errors/

interface Props {
  step: StartSteps
}

const StartInfoPopup: React.FC<Props> = ({ step }) => {
  const { t } = useTranslation()
  const error = step !== StartSteps.Waiting && step !== StartSteps.Ready
  const retry = error && step !== StartSteps.WebviewError
  const link = step === StartSteps.WebviewError

  const handleRetry = () => {
    window.location.pathname = '/'
  }

  const texts = useMemo<string[] | undefined>(() => {
    switch (step) {
      case StartSteps.Ready:
        return undefined

      case StartSteps.Waiting:
        return [t('start-info.waiting-devices'), t('start-info.allow-access')]

      case StartSteps.NotFoundError:
        // Required track is missing
        return [t('start-info.notfound-error'), t('start-info.notfound-advice')]

      case StartSteps.NotReadableError:
        // Webcam or mic are already in use
        return [t('start-info.reading-error'), t('start-info.reading-advice')]

      case StartSteps.NotAllowedError:
      case StartSteps.PermissionDismissedError:
        // Permission denied in browser
        return [t('start-info.denied-error'), t('start-info.denied-advice')]

      case StartSteps.WebviewError:
        // Permission denied in browser
        return [t('start-info.webview-error'), t('start-info.webview-advice')]

      // case StartSteps.TypeError:
      // // Empty constraints object

      // case StartSteps.OverconstrainedError:
      // // Constraints can not be satisfied by avb. devices

      default:
        return [t('start-info.other-error'), t('start-info.other-advice')]
    }
  }, [t, step])

  return texts ? (
    <Popup error={error}>
      {texts.map((text, i) => (
        <p key={i}>{text}</p>
      ))}

      {link && <LinkField />}

      {retry && <Button onClick={handleRetry}>{t('start-info.retry')}</Button>}
    </Popup>
  ) : null
}

export default StartInfoPopup
