import { useEffect, useRef, useState } from 'react'

export default function useFPS(): number {
  const frameRequest = useRef(0)
  const [fps, setFPS] = useState(0)

  useEffect(() => {
    let fpsCount = 0
    let prevTime = new Date().getTime()

    function count() {
      const time = new Date().getTime()
      const timeDiff = (time - prevTime) / 1000
      fpsCount++
      if (timeDiff >= 1) {
        setFPS(Math.round(fpsCount / timeDiff))
        fpsCount = 0
        prevTime = time
      }
      frameRequest.current = requestAnimationFrame(count)
    }
    count()

    return () => cancelAnimationFrame(frameRequest.current)
  }, [])

  return fps
}
