import styled from '@emotion/styled'
import settings from '../../settings'

interface Props {
  targetX: number
  targetY: number
  size: number
  moveDistance: number
}

const PlayerContainer = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(
    ${props => props.targetX}px,
    ${props => props.targetY}px
  );
  will-change: transform;
  transition: transform
    ${({ moveDistance }) => moveDistance / settings.player.moveSpeed}s linear;
`

export default PlayerContainer
