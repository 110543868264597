import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as SvgBackground } from '../../assets/backgrounds/background1.svg'
import { RootState } from '../../store'

const Background: React.FC = () => {
  const background = useSelector((state: RootState) => state.room.background)
  const backgroundSize = useMemo(
    () => ({
      width: background.width * background.scale,
      height: background.height * background.scale
    }),
    [background]
  )

  return (
    <SvgBackground
      x="0"
      y="0"
      width={backgroundSize.width}
      height={backgroundSize.height}
      style={{ cursor: 'move' }}
    />
  )
}

export default memo(Background)
