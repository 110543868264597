import React from 'react'
import { getMicAudioAnalyser } from '../../devices/audio/microphone'
import { getAudioAnalyser } from '../../devices/audio/speaker'
import useFPS from '../../hooks/useFPS'
import AudioFreq from './AudioFreq'
import usePeersStats from './usePeersStats'

const units = ['kbps', 'Mbps', 'Gbps']
function humanBandwidth(n: number) {
  let i = 0
  while (n >= 1024) {
    i++
    n /= 1024
  }
  return `${n.toFixed(1)} ${units[i]}`
}

const DebugData: React.FC = () => {
  const fps = useFPS()
  const peerStats = usePeersStats()

  return (
    <>
      <p>
        <span>FPS</span>
        <span>{fps}</span>
      </p>
      <p>
        <span>Video 🠟</span>
        <span>{humanBandwidth(peerStats.videoDown)}</span>
      </p>
      <p>
        <span>Video 🠝</span>
        <span>{humanBandwidth(peerStats.videoUp)}</span>
      </p>
      <p>
        <span>Audio 🠟</span>
        <span>{humanBandwidth(peerStats.audioDown)}</span>
      </p>
      <p>
        <span>Audio 🠝</span>
        <span>{humanBandwidth(peerStats.audioUp)}</span>
      </p>
      <p>
        <span>Microphone</span>
        <AudioFreq analyser={getMicAudioAnalyser()} />
      </p>
      <p>
        <span>Speaker</span>
        <AudioFreq analyser={getAudioAnalyser()} />
      </p>
    </>
  )
}

export default DebugData
