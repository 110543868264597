import { MediaConnection } from 'peerjs'
import { peer } from '.'

type RTCReport =
  | RTCStatsReportTransport
  | RTCStatsReportPeerConnection
  | RTCStatsReportStream
  | RTCStatsReportTrack
  | RTCStatsReportInboundRTP
  | RTCStatsReportOutboundRTP

interface RTCStatsReportTransport {
  id: string
  timestamp: number
  type: 'transport'
  bytesSent: number
  bytesReceived: number
}

interface RTCStatsReportPeerConnection {
  id: string
  timestamp: number
  type: 'peer-connection'
  dataChannelsOpened: number
  dataChannelsClosed: number
}
interface RTCStatsReportStream {
  id: string
  timestamp: number
  type: 'stream'
  streamIdentifier: string
  trackIds: string[]
}

interface RTCStatsReportTrack {
  id: string
  timestamp: number
  type: 'track'
  trackIdentifier: string
  remoteSource: boolean
  ended: boolean
  detached: boolean
  kind: 'video' | 'audio'
  jitterBufferDelay: number
  jitterBufferEmittedCount: number
  frameWidth: number
  frameHeight: number
  framesReceived: number
  framesDecoded: number
  framesDropped: number
}

interface RTCStatsReportInboundRTP {
  id: string
  timestamp: number
  type: 'inbound-rtp'
  ssrc: number
  isRemote: false
  mediaType: 'video' | 'audio'
  kind: 'video' | 'audio'
  trackId: string
  transportId: string
  codecId: string
  firCount: number
  pliCount: number
  nackCount: number
  qpSum: number
  packetsReceived: number
  bytesReceived: number
  headerBytesReceived: number
  packetsLost: number
  lastPacketReceivedTimestamp: number
  framesDecoded: number
  keyFramesDecoded: number
  totalDecodeTime: number
  totalInterFrameDelay: number
  totalSquaredInterFrameDelay: number
  decoderImplementation: string
}

interface RTCStatsReportOutboundRTP {
  id: string
  timestamp: number
  type: 'outbound-rtp'
  ssrc: number
  isRemote: false
  mediaType: 'video' | 'audio'
  kind: 'video' | 'audio'
  trackId: string
  transportId: string
  codecId: string
  firCount: number
  pliCount: number
  nackCount: number
  qpSum: number
  mediaSourceId: string
  packetsSent: number
  retransmittedPacketsSent: number
  bytesSent: number
  headerBytesSent: number
  retransmittedBytesSent: number
  framesEncoded: number
  keyFramesEncoded: number
  totalEncodeTime: number
  totalEncodedBytesTarget: number
  totalPacketSendDelay: number
  qualityLimitationReason: string
  qualityLimitationResolutionChanges: number
  encoderImplementation: string
}

export interface PeersStats {
  videoUp: number
  videoDown: number
  audioUp: number
  audioDown: number
}

let prevStats: RTCStatsReport[] = []

export async function getPeersStats(): Promise<PeersStats> {
  // Retrieve stats
  const statsReports = await Promise.all(
    Object.values(
      peer.connections as Record<string, MediaConnection[]>
    ).flatMap(peer => peer.map(call => call.peerConnection.getStats()))
  )

  // Aggregate from stats reports
  const nextStats = {
    videoUp: 0,
    videoDown: 0,
    audioUp: 0,
    audioDown: 0
  }
  statsReports.forEach(reports => {
    reports.forEach((report: RTCReport) => {
      if (report.type === 'outbound-rtp') {
        const prevReport: typeof report = prevStats
          .find(s => s.has(report.id))
          ?.get(report.id)
        if (prevReport) {
          // Bytes changes over time
          const bytesSent =
            (8 *
              (report.bytesSent +
                (report.headerBytesSent || 0) -
                (prevReport.bytesSent + (prevReport.headerBytesSent || 0)))) /
            (report.timestamp - prevReport.timestamp)

          // Add bytes
          if (report.kind === 'video') nextStats.videoUp += bytesSent
          else if (report.kind === 'audio') nextStats.audioUp += bytesSent
        }
      } else if (report.type === 'inbound-rtp') {
        const prevReport: typeof report = prevStats
          .find(s => s.has(report.id))
          ?.get(report.id)
        if (prevReport) {
          // Bytes changes over time
          const bytesReceived =
            (8 *
              (report.bytesReceived +
                (report.headerBytesReceived || 0) -
                (prevReport.bytesReceived +
                  (prevReport.headerBytesReceived || 0)))) /
            (report.timestamp - prevReport.timestamp)

          // Add bytes
          if (report.kind === 'video') nextStats.videoDown += bytesReceived
          else if (report.kind === 'audio') nextStats.audioDown += bytesReceived
        }
      }
    })
  })
  prevStats = statsReports

  return nextStats
}
