import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import settings from '../settings'
import store, { actions, RootState } from '../store'
import { getDistance } from '../utils/getDistance'

const moveStepDuration =
  (settings.player.moveStep / settings.player.moveSpeed) * 1000

interface PressedKeys {
  up: boolean
  down: boolean
  left: boolean
  right: boolean
}

const defaultPressedKeys = {
  up: false,
  down: false,
  left: false,
  right: false
}

export default function usePlayerMove(
  getWorldPosition: (
    clientX: number,
    clientY: number
  ) => { x: number; y: number },
  centerOnPlayer: (onlyIfOnborder?: boolean) => void
) {
  const dispatch = useDispatch()
  const background = useSelector((state: RootState) => state.room.background)

  const clickShiftRef = useRef<{ x: number; y: number }>()
  const moveTargetRef = useRef<{ x: number; y: number }>()
  const moveIntervalRef = useRef<number>()
  const pressedKeysRef = useRef<PressedKeys>(defaultPressedKeys)

  const moveFollowingMouse = useCallback(() => {
    let moveTarget = moveTargetRef.current
    const { up, down, left, right } = pressedKeysRef.current
    if (!(moveTarget || up || down || left || right)) {
      return
    }

    const { x, y } = store.getState().player

    // Move with arrow
    if (!moveTarget) {
      if (up && left) {
        moveTarget = { x: x - 200, y: y - 200 }
      } else if (up && right) {
        moveTarget = { x: x + 200, y: y - 200 }
      } else if (down && left) {
        moveTarget = { x: x - 200, y: y + 200 }
      } else if (down && right) {
        moveTarget = { x: x + 200, y: y + 200 }
      } else if (up) {
        moveTarget = { x, y: y - 200 }
      } else if (down) {
        moveTarget = { x, y: y + 200 }
      } else if (left) {
        moveTarget = { x: x - 200, y }
      } else if (right) {
        moveTarget = { x: x + 200, y }
      } else {
        moveTarget = { x, y }
      }
    }

    // Compute target point
    const targetPoint = { ...moveTarget }
    const clickShift = clickShiftRef.current
    if (clickShift) {
      targetPoint.x -= clickShift.x
      targetPoint.y -= clickShift.y
    }

    // Compute distance from current position
    const moveVector = [targetPoint.x - x, targetPoint.y - y]
    const distance = getDistance(x, y, targetPoint.x, targetPoint.y)

    // Move by step
    if (distance > settings.player.moveStep) {
      moveVector[0] *= settings.player.moveStep / distance
      moveVector[1] *= settings.player.moveStep / distance
    }
    const newPosition = { x: x + moveVector[0], y: y + moveVector[1] }

    // Apply position and send it to other players
    dispatch(actions.player.setPosition(newPosition.x, newPosition.y))

    // Pan
    centerOnPlayer(true)
  }, [dispatch, centerOnPlayer])

  useEffect(() => {
    // Assign random position to player
    const [topLeft, bottomRight] = background.spawnArea
    const { scale } = background
    dispatch(
      actions.player.setPosition(
        Math.random() * (bottomRight[0] - topLeft[0]) * scale +
          topLeft[0] * scale,
        Math.random() * (bottomRight[1] - topLeft[1]) * scale +
          topLeft[1] * scale,
        true
      )
    )

    // And zoom
    setTimeout(() => centerOnPlayer(), 500)

    moveIntervalRef.current = window.setInterval(
      moveFollowingMouse,
      moveStepDuration
    )

    const handleKeydown = (event: KeyboardEvent) => {
      const pressedKeys = pressedKeysRef.current
      let changeMovement = false
      if (event.keyCode === 37 || event.key === 'a') {
        if (!pressedKeys.left) {
          pressedKeys.left = true
          changeMovement = true
        }
      } else if (event.keyCode === 38 || event.key === 'w') {
        if (!pressedKeys.up) {
          pressedKeys.up = true
          changeMovement = true
        }
      } else if (event.keyCode === 39 || event.key === 'd') {
        if (!pressedKeys.right) {
          pressedKeys.right = true
          changeMovement = true
        }
      } else if (event.keyCode === 40 || event.key === 's') {
        if (!pressedKeys.down) {
          pressedKeys.down = true
          changeMovement = true
        }
      }

      if (changeMovement) {
        clickShiftRef.current = undefined
        moveFollowingMouse()
        window.clearInterval(moveIntervalRef.current)
        moveIntervalRef.current = window.setInterval(
          moveFollowingMouse,
          moveStepDuration
        )
      }
    }
    const handleKeyup = (event: KeyboardEvent) => {
      const pressedKeys = pressedKeysRef.current
      if (event.keyCode === 37 || event.key === 'a') {
        pressedKeys.left = false
      } else if (event.keyCode === 38 || event.key === 'w') {
        pressedKeys.up = false
      } else if (event.keyCode === 39 || event.key === 'd') {
        pressedKeys.right = false
      } else if (event.keyCode === 40 || event.key === 's') {
        pressedKeys.down = false
      }
    }

    document.addEventListener('keydown', handleKeydown)
    document.addEventListener('keyup', handleKeyup)

    // Unmount PanZoom at unmount
    return () => {
      document.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('keyup', handleKeyup)
      window.clearInterval(moveIntervalRef.current)
    }
  }, [background, dispatch, moveFollowingMouse, centerOnPlayer])

  const handleCurrentPlayerTouchStart = useCallback(
    (clientX: number, clientY: number) => {
      const position = getWorldPosition(clientX, clientY)
      const { x, y } = store.getState().player
      moveTargetRef.current = position
      clickShiftRef.current = {
        x: position.x - x,
        y: position.y - y
      }
    },
    [getWorldPosition]
  )

  const handleMouseMove = useCallback<React.MouseEventHandler<Element>>(
    event => {
      if (moveTargetRef.current) {
        moveTargetRef.current = getWorldPosition(event.clientX, event.clientY)
      }
    },
    [getWorldPosition]
  )

  const handleTouchMove = useCallback<React.TouchEventHandler<Element>>(
    event => {
      if (event.touches.length === 1 && moveTargetRef.current) {
        const touch = event.touches[0]
        moveTargetRef.current = getWorldPosition(touch.clientX, touch.clientY)
      }
    },
    [getWorldPosition]
  )

  const handleMouseUp = useCallback<React.MouseEventHandler<Element>>(() => {
    moveTargetRef.current = undefined
  }, [])

  const handleTouchEnd = useCallback<React.TouchEventHandler<Element>>(() => {
    moveTargetRef.current = undefined
  }, [])

  return {
    handleCurrentPlayerTouchStart,
    handleMouseMove,
    handleTouchMove,
    handleMouseUp,
    handleTouchEnd
  }
}
