import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import Player, { PlayerModes } from './Player'

interface Props {
  onTouchStart?: (clientX: number, clientY: number) => void
  x?: number
  y?: number
}

const CurrentPlayer: React.FC<Props> = ({ onTouchStart, x, y }) => {
  const player = useSelector((state: RootState) => state.player)

  const handleMouseDown = useCallback<React.MouseEventHandler<Element>>(
    event => {
      event.preventDefault()
      if (!onTouchStart) return
      onTouchStart(event.clientX, event.clientY)
    },
    [onTouchStart]
  )

  const handleTouchStart = useCallback<React.TouchEventHandler<Element>>(
    event => {
      if (!onTouchStart) return
      if (event.touches.length === 1) {
        const touch = event.touches[0]
        onTouchStart(touch.clientX, touch.clientY)
      }
    },
    [onTouchStart]
  )

  return (
    <div onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}>
      <Player mode={PlayerModes.Current} player={player} x={x} y={y} />
    </div>
  )
}

export default CurrentPlayer
