import styled from '@emotion/styled'

interface Props {
  x: number
  y: number
}

const fontSize = 25

const PlayerTitle = styled.div<Props>`
  position: absolute;
  top: ${({ y }) => y - fontSize}px;
  left: ${({ x }) => x - 100}px;
  color: #eee;
  width: 200px;
  height: ${fontSize}px;
  line-height: ${fontSize}px;
  font-size: ${fontSize}px;
  text-align: center;
  text-shadow: 0px 2px 2px black;
`

export default PlayerTitle
