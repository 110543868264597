import * as faceapi from 'face-api.js'
import settings from '../../settings'
import { Box } from './interfaces'

// Config
const inputSize = 256
const scoreThreshold = 0.3
const faceMargin = {
  top: -0.4,
  bottom: 0.1,
  left: -0,
  right: 0
}

// Load model
let modelLoaded = false
faceapi.nets.tinyFaceDetector
  .load('/faceapi')
  .then(() => (modelLoaded = true))
  .catch(error => console.error('Error loading FaceDetector Net', error))

// Options
const faceDetectorOptions = new faceapi.TinyFaceDetectorOptions({
  inputSize,
  scoreThreshold
})

const canvas = document.createElement('canvas')
const context = canvas.getContext('2d')

export async function detectFaces(video: HTMLVideoElement): Promise<Box[]> {
  if (!modelLoaded || video.videoWidth === 0) return []

  if (canvas.width !== video.videoWidth) {
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
  }
  context?.drawImage(video, 0, 0)

  // Detect all faces with Face API
  // We could provide directly a video, but it creates a canvas on each frame
  // The canvas creation in this file is a small optimization
  const faces = await faceapi.detectAllFaces(canvas, faceDetectorOptions)

  return (
    faces
      .map(face => {
        let { x, y, width, height } = face.box

        // Apply margins
        x += faceMargin.left * width
        y += faceMargin.top * height
        width += (faceMargin.right - faceMargin.left) * width
        height += (faceMargin.bottom - faceMargin.top) * height

        // Reshape to square
        const shift = Math.abs((width - height) / 2)
        if (width > height) {
          height = width
          y -= shift
        } else {
          width = height
          x -= shift
        }

        // Clamp square to viewport
        if (height > video.videoHeight) {
          const centerX = (x + width) / 2
          height = video.videoHeight
          width = height
          x = centerX - width / 2
        }
        if (x < 0) {
          x = 0
        } else if (x + width > video.videoWidth) {
          x = video.videoWidth - width
        }
        if (y < 0) {
          y = 0
        } else if (y + height > video.videoHeight) {
          y = video.videoHeight - height
        }

        return { x, y, width, height }
      })
      .slice(0, settings.video.maxFaces)
      // Sort by X center
      .sort((box1, box2) =>
        box1.x + box1.width / 2 > box2.x + box2.width / 2 ? 1 : -1
      )
  )
}
