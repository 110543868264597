import styled from '@emotion/styled'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

interface Props {
  x: number
  y: number
}

const StyledTitle = styled.div<Props>`
  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  font-size: 25px;
  color: #eee;
  text-shadow: 0 2px 2px #000;
`

const Title: React.FC<Props> = props => {
  const roomId = useSelector((state: RootState) => state.room.id)

  return <StyledTitle {...props}>{roomId}</StyledTitle>
}

export default memo(Title)
