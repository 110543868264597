import React, { useRef, useState } from 'react'

type Inputs = HTMLInputElement | HTMLTextAreaElement
type Buttons = Inputs | HTMLButtonElement

export default function useInputCopy<
  InputElement extends Inputs = HTMLInputElement
>() {
  const [copied, setCopied] = useState(false)
  const inputRef = useRef<InputElement>(null)

  function copy(event?: React.MouseEvent<Buttons>) {
    if (!inputRef.current) return
    inputRef.current.select()
    document.execCommand('copy')
    event?.currentTarget.focus()
    setCopied(true)
  }

  return { inputRef, copied, copy }
}
