import styled from '@emotion/styled'
import * as Sentry from '@sentry/browser'
import isWebview from 'is-ua-webview'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { startDevices } from '../../devices'
import StartForm from '../common/StartForm'
import StartInfoPopup from '../common/StartInfoPopup'
import CurrentPlayer from '../player/CurrentPlayer'
import Settings from '../settings/Settings'

const Layout = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

const AvatarContainer = styled.header`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & > div {
    position: absolute;
    height: 0;
    width: 0;
  }
`

const Header = styled.header`
  text-align: center;
  h1 {
    font-size: 1.8rem;
    color: #00c1ff;
  }
`

export enum StartSteps {
  Waiting,
  Ready,
  WebviewError,
  NotFoundError,
  NotReadableError,
  OverconstrainedError,
  NotAllowedError,
  TypeError,
  PermissionDismissedError,
  OtherError
}

const HomePage: React.FC = () => {
  const [step, setStep] = useState(StartSteps.Waiting)
  const { t } = useTranslation()

  useEffect(() => {
    ReactGA.pageview('/')

    // Show error if in WebView
    if (isWebview(navigator.userAgent || '')) {
      return setStep(StartSteps.WebviewError)
    }

    // Start mic and cam
    startDevices()
      .then(() => setStep(StartSteps.Ready))
      .catch((error: Error) => {
        console.error(error)
        const nextStep: StartSteps =
          StartSteps[error.name as keyof typeof StartSteps]
        if (nextStep) {
          setStep(nextStep)
        } else {
          setStep(StartSteps.OtherError)
          Sentry.captureException(error)
        }
      })
  }, [])

  return (
    <>
      <Layout>
        <Header>
          <h1>Yapo</h1>
          <p>{t('home.headline')}</p>
        </Header>

        <AvatarContainer>
          <div>
            <CurrentPlayer x={0} y={0} />
          </div>
        </AvatarContainer>

        <StartForm disabled={step !== StartSteps.Ready} />
      </Layout>

      <Settings />
      <StartInfoPopup step={step} />
    </>
  )
}

export default HomePage
