import Peer from 'peerjs'
import settings from '../../settings'
import store from '../../store'

export const peer = new Peer(
  store.getState().player.peerId,
  settings.p2p.peerjs
)

export type CallHandler = (call: Peer.MediaConnection) => boolean

const callHandlers: CallHandler[] = []
const pendingCalls: Peer.MediaConnection[] = []

peer.on('call', call => {
  // Test call handlers
  for (const callHandler of callHandlers) {
    if (callHandler(call)) return
  }

  // If no handler worked, add to pending calls
  pendingCalls.push(call)
})

export function addCallHandler(handler: CallHandler) {
  callHandlers.push(handler)

  // Execute pending calls
  for (let i = 0; i < pendingCalls.length; i++) {
    const pendingCall = pendingCalls[i]
    if (handler(pendingCall)) {
      pendingCalls.splice(i, 1)
      break
    }
  }
}

export function removeCallHandler(handler: CallHandler) {
  const index = callHandlers.indexOf(handler)
  if (index !== -1) {
    callHandlers.splice(index, 1)
  }
}
