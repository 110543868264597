import Peer from 'peerjs'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  addCallHandler,
  CallHandler,
  peer,
  removeCallHandler
} from '../network/p2p'
import { CallMetadata } from '../network/p2p/interfaces'
import settings from '../settings'
import store, { RootState } from '../store'
import useIsMounted from './useIsMounted'

export function useAudioCall(peerId: string): MediaStream | undefined {
  const callRef = useRef<Peer.MediaConnection>()
  const [stream, setStream] = useState<MediaStream>()
  const retryTimeoutRef = useRef<number>()
  const [retry, setRetry] = useState(0)
  const isMounted = useIsMounted()
  const muted = useSelector((state: RootState) => !state.player.audio)

  const closeCall = (): void => {
    window.clearTimeout(retryTimeoutRef.current)
    callRef.current?.close()
    callRef.current = undefined
  }

  // Initiate call
  useEffect(() => {
    // Get stream from store
    const stream = store.getState().streams.audio
    if (!stream || muted) return

    // New call
    // Send microphone audio stream
    const metadata: CallMetadata = { audio: true }
    const call = peer.call(peerId, stream, { metadata })
    callRef.current = call

    if (settings.p2p.debug) {
      console.log('[P2P] Call audio', peerId)
    }

    const retryCall = (): void => {
      closeCall()
      retryTimeoutRef.current = window.setTimeout(() => {
        if (!isMounted()) return
        setRetry(r => r + 1)
      }, settings.audio.retryDelay)
    }

    if (call) {
      // Handle errors => disconnection, reconnection
      call.on('error', async error => {
        if (!isMounted()) return
        if (settings.p2p.debug) {
          console.warn('[P2P] Call audio error (emitter)', error)
        }
        retryCall()
      })
    } else {
      retryCall()
    }

    return () => closeCall()
  }, [peerId, muted, retry, isMounted])

  // Receive call
  useEffect(() => {
    let callReceived: Peer.MediaConnection | undefined

    const handleCall: CallHandler = call => {
      const { audio } = call.metadata as CallMetadata
      if (call.peer !== peerId || !audio) return false

      if (settings.p2p.debug) {
        console.log('[P2P] Answering audio call', call.peer)
      }

      if (callReceived?.open) callReceived.close()
      callReceived = call
      call.answer()

      // Get stream
      call.on('stream', stream => setStream(stream))

      // Handle error / disconnection
      call.on('error', error => {
        if (!isMounted()) return
        setStream(undefined)
        if (settings.p2p.debug) {
          console.warn('[P2P] Call audio error (receiver)', error)
        }
      })

      return true
    }

    addCallHandler(handleCall)
    return () => {
      removeCallHandler(handleCall)
      if (callReceived) {
        callReceived.close()
        callReceived = undefined
      }
    }
  }, [peerId, isMounted])

  return stream
}
