import settings from '../settings'

export function getVideoBandwidthByDistance(distance: number): number {
  if (distance >= settings.video.distanceStop) {
    return 0
  }

  let bandwidth = settings.video.bandwidth
  for (const halfDistance of settings.video.distancesHalfBandwidth) {
    if (distance >= halfDistance) {
      bandwidth /= 2
    } else {
      break
    }
  }
  return bandwidth
}
