import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ClickSVG } from '../../assets/icons/click.svg'
import { ReactComponent as DiscussSVG } from '../../assets/icons/discuss.svg'
import { ReactComponent as HeadsetSVG } from '../../assets/icons/headset.svg'
import Button from './Button'
import Popup from './Popup'

enum Steps {
  Discuss,
  Moves,
  Headset,
  End
}

interface Props {
  onComplete: () => void
}

const Tuto: React.FC<Props> = ({ onComplete }) => {
  const [step, setStep] = useState(0)
  const handleNextStep = () => setStep(step => step + 1)
  const { t } = useTranslation()

  useEffect(() => {
    if (step === Steps.End) {
      onComplete()
    }
  }, [step, onComplete])

  if (step === Steps.Headset) {
    return (
      <Popup width={500}>
        <p>
          <HeadsetSVG width="100" fill="#eee" />
        </p>
        <p>{t('tuto.headset')}</p>
        <p>
          <Button onClick={handleNextStep} autoFocus>
            {t('tuto.next')}
          </Button>
        </p>
      </Popup>
    )
  }

  if (step === Steps.Moves) {
    return (
      <Popup width={500}>
        <p>
          <ClickSVG width="100" fill="#eee" />
        </p>
        <p>{t('tuto.moves')}</p>
        <p>
          <Button onClick={handleNextStep} autoFocus>
            {t('tuto.next')}
          </Button>
        </p>
      </Popup>
    )
  }

  if (step === Steps.Discuss) {
    return (
      <Popup width={500}>
        <p>
          <DiscussSVG width="100" fill="#eee" />
        </p>
        <p>{t('tuto.discuss')}</p>
        <p>
          <Button onClick={handleNextStep} autoFocus>
            {t('tuto.next')}
          </Button>
        </p>
      </Popup>
    )
  }

  return null
}

export default Tuto
