import React from 'react'
import { TransformComponent } from 'react-zoom-pan-pinch'
import usePlayerMove from '../../hooks/usePlayerMove'
import useScenePosition from '../../hooks/useScenePosition'
import useWindowSize from '../../hooks/useWindowSize'
import Background from './Background'
import Players from './Players'
import Title from './Title'
import { TransformWrapperHandlerProps } from './transformInterfaces'
import ZoomButton from './ZoomButton'

type Props = TransformWrapperHandlerProps

const SceneWrapper: React.FC<Props> = ({
  positionX,
  positionY,
  scale,
  zoomIn,
  zoomOut,
  setTransform
}) => {
  const windowSize = useWindowSize()

  const { centerOnPlayer, getWorldPosition } = useScenePosition(
    positionX,
    positionY,
    scale,
    setTransform
  )
  const {
    handleCurrentPlayerTouchStart,
    handleMouseMove,
    handleTouchMove,
    handleMouseUp,
    handleTouchEnd
  } = usePlayerMove(getWorldPosition, centerOnPlayer)

  return (
    <>
      <TransformComponent>
        <div
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <Background />
          <Players onCurrentPlayerTouchStart={handleCurrentPlayerTouchStart} />
        </div>
      </TransformComponent>

      <Title x={15} y={10} />

      <ZoomButton x={5} y={windowSize.height - (5 + 30) * 2} onClick={zoomIn}>
        +
      </ZoomButton>
      <ZoomButton x={5} y={windowSize.height - 5 - 30} onClick={zoomOut}>
        -
      </ZoomButton>
    </>
  )
}

export default SceneWrapper
