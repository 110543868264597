import Peer from 'peerjs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { peer } from '../network/p2p'
import { CallMetadata } from '../network/p2p/interfaces'
import settings from '../settings'
import { createEmptyMediaStream } from '../utils/createEmptyMediaStream'
import useIsMounted from './useIsMounted'

const emptyStream = createEmptyMediaStream()

export function useVideoCall(
  peerId: string,
  faceIndex: number,
  distance: number
): MediaStream {
  const callRef = useRef<Peer.MediaConnection>()
  const retryTimeoutRef = useRef<number>()
  const [stream, setStream] = useState<MediaStream>(() => new MediaStream())
  const [retry, setRetry] = useState(0)
  const isMounted = useIsMounted()
  const active = useMemo(() => distance < settings.video.distanceStop, [
    distance
  ])

  const closeCall = () => {
    window.clearTimeout(retryTimeoutRef.current)
    callRef.current?.close()
    callRef.current = undefined
  }

  // Initiate call
  useEffect(() => {
    if (!active) return

    // New call
    // Send empty video MediaStream and wait for answer with webcam stream
    const metadata: CallMetadata = { faceIndex, audio: false }
    const call = peer.call(peerId, emptyStream, {
      metadata
    })
    callRef.current = call

    if (settings.p2p.debug) {
      console.log('[P2P] Call video', peerId, faceIndex)
    }

    const retryCall = () => {
      closeCall()
      retryTimeoutRef.current = window.setTimeout(() => {
        if (!isMounted()) return
        setRetry(r => r + 1)
      }, settings.video.retryDelay)
    }

    if (call) {
      // Receive video stream
      call.on('stream', stream => {
        if (!isMounted()) return
        if (settings.p2p.debug) {
          console.log('[P2P] Call video stream', peerId, faceIndex, stream)
        }
        setStream(stream)
      })

      // Handle errors => disconnection, reconnection
      call.on('error', async error => {
        if (!isMounted()) return
        if (settings.p2p.debug) {
          console.warn('[P2P] Call video error (emitter)', error)
        }
        retryCall()
      })
    } else {
      retryCall()
    }

    return () => closeCall()
  }, [peerId, faceIndex, retry, active, isMounted])

  return stream
}
