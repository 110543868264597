import styled from '@emotion/styled'
import React, { useLayoutEffect, useRef } from 'react'
import settings from '../../settings'

const borderSize = settings.player.avatar.border

interface Props extends VideoProps {
  stream?: MediaStream
}

interface VideoProps {
  mirror?: boolean
  x: number
  y: number
  color: string
  size: number
  draggable?: boolean
}

const Video = styled.video<VideoProps>`
  position: absolute;
  top: ${({ y, size }) => y - size / 2}px;
  left: ${({ x, size }) => x - size / 2}px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  border: ${borderSize}px solid ${({ color }) => color};
  box-shadow: 0 2px 2px black;
  transform: ${({ mirror }) => (mirror ? 'rotateY(180deg)' : 'none')};
  cursor: ${({ draggable }) => (draggable ? 'grab' : 'default')};
`

const StreamAvatar: React.FC<Props> = ({ stream, draggable, ...props }) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useLayoutEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream
    }
  }, [stream])

  return (
    <Video
      ref={videoRef}
      autoPlay
      playsInline
      muted
      loop
      className={draggable ? 'no-pan' : ''}
      draggable={draggable}
      {...props}
    />
  )
}

export default StreamAvatar
