import settings from '../../settings'
import store, { actions } from '../../store'
import { stopStream } from '../../utils/stopStream'
import { getDefaultBox, transitionBox } from './box'
import { detectFaces } from './detectFaces'
import { Face } from './interfaces'

const canvasSize = settings.video.canvasSize

// State
let mediaStream: MediaStream
const video = createVideo()
const faces: Face[] = []
let updating = false
let lastFacesUpdate = 0
let lastFaceFoundTime = 0

export function getWebcamConstraints(): MediaStreamConstraints {
  const { videoinput } = store.getState().settings
  return {
    video: videoinput
      ? { deviceId: { ideal: videoinput } }
      : { facingMode: 'user' }
  }
}

async function getMediaStream(
  tracks?: MediaStreamTrack[]
): Promise<MediaStream> {
  if (tracks) {
    return new MediaStream(tracks.filter(track => track.kind === 'video'))
  } else {
    // Get stream from microphone
    return navigator.mediaDevices.getUserMedia(getWebcamConstraints())
  }
}

export async function startWebcam(tracks?: MediaStreamTrack[]): Promise<void> {
  // Get stream from webcam
  mediaStream = await getMediaStream(tracks)

  // Store deviceId
  const track = mediaStream.getVideoTracks()[0]
  const id = track && track.getCapabilities && track.getCapabilities().deviceId
  if (id) store.dispatch(actions.settings.setVideoInput(id) as any)

  // Stream webcam to a video element
  video.srcObject = mediaStream
  video.onloadedmetadata = () => {
    requestAnimationFrame(onUpdate)
  }
}

const onUpdate = async () => {
  // Draw each face in their canvas
  for (const face of faces) {
    if (!face.context) continue
    transitionBox(face.box, face.nextBox)
    const { x, y, width, height } = face.box
    face.context.drawImage(
      video,
      x,
      y,
      width,
      height,
      0,
      0,
      canvasSize,
      canvasSize
    )
  }

  requestAnimationFrame(onUpdate)

  // Skip detecting faces if done recently
  const time = new Date().getTime()
  if (
    updating ||
    lastFacesUpdate > time - settings.video.faceDetectionInterval
  ) {
    return
  }
  updating = true

  // Detect faces
  const faceBoxes = await detectFaces(video)
  let streamsChanged = false

  // No face found, take default box
  if (faceBoxes.length === 0) {
    if (time - lastFaceFoundTime > settings.video.noFaceDefaultDelay) {
      faceBoxes.push(getDefaultBox(video))
    }
  } else {
    lastFaceFoundTime = time
  }

  // Compare new found faces to old ones, and keep them in ref
  for (let i = 0; i < faceBoxes.length; i++) {
    if (faces[i]) {
      faces[i].nextBox = faceBoxes[i]
    } else {
      const { canvas, context, stream } = createStreamingCanvas()
      faces[i] = {
        nextBox: faceBoxes[i],
        box: faceBoxes[i],
        canvas,
        context,
        stream
      }
      streamsChanged = true
    }
  }

  // Remove faces that are no longer detected
  const goalLength = Math.max(1, faceBoxes.length)
  while (faces.length > goalLength) {
    stopStream(faces[faces.length - 1].stream)
    faces.pop()
    streamsChanged = true
  }

  // Update streams in store
  if (streamsChanged) {
    await store.dispatch(
      actions.streams.setVideos(faces.map(({ stream }) => stream)) as any
    )
  }

  await updateFacesInStore()

  lastFacesUpdate = new Date().getTime()
  updating = false
}

export async function stopWebcam(): Promise<void> {
  if (mediaStream) stopStream(mediaStream)
  faces.length = 0
  await store.dispatch(actions.streams.setVideos([]) as any)
}

function createVideo(): HTMLVideoElement {
  const video = document.createElement('video')
  video.setAttribute('autoplay', '1')
  video.setAttribute('playsinline', '1')
  video.setAttribute('muted', '1')
  video.setAttribute('loop', '1')
  return video
}

function createStreamingCanvas(): {
  canvas: HTMLCanvasElement
  context: CanvasRenderingContext2D | undefined
  stream: any
} {
  const canvas = document.createElement('canvas')
  canvas.width = canvasSize
  canvas.height = canvasSize
  const context = canvas.getContext('2d') || undefined
  const stream = (canvas as any).captureStream(15)
  return { canvas, context, stream }
}

async function updateFacesInStore(): Promise<void> {
  const {
    player: { faces: storeFaces }
  } = store.getState()
  if (faces.length !== storeFaces) {
    await store.dispatch(actions.player.setFaces(faces.length) as any)
  }
}
