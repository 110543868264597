import { prepareStore } from 'redux-zap'

export interface SettingsState {
  readonly audioinput?: string
  readonly audiooutput?: string
  readonly videoinput?: string
  readonly micVolumeThreshold: number
  readonly faceDetection: boolean
}

const initialState: SettingsState = {
  micVolumeThreshold: -70,
  faceDetection: true
}

export default prepareStore(initialState, {
  setAudioInput: (audioinput?: string) => ({ audioinput }),
  setAudioOutput: (audiooutput?: string) => ({ audiooutput }),
  setVideoInput: (videoinput?: string) => ({ videoinput }),
  setMicThreshold: (micVolumeThreshold: number) => ({ micVolumeThreshold }),
  setFaceDetection: (faceDetection: boolean) => ({ faceDetection })
})
