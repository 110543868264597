/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useInputCopy from '../../hooks/useInputCopy'
import Button from './Button'
import Input from './Input'

const LinkField: React.FC = () => {
  const { t } = useTranslation()
  const { inputRef, copied, copy } = useInputCopy<HTMLInputElement>()

  return (
    <p>
      <Input
        type="text"
        ref={inputRef}
        value={window.location.href}
        onClick={e => e.currentTarget.select()}
        big
        css={css`
          width: 300px;
          text-align: center;
        `}
      />{' '}
      <Button big onClick={copy}>
        {t(copied ? 'share.copied' : 'share.copy')}
      </Button>
    </p>
  )
}

export default LinkField
