import React from 'react'
import { useVideoAnswer } from '../../hooks/useVideoAnswer'
import Player, { PlayerProps } from './Player'

export enum PlayerModes {
  Current,
  WithinReach,
  OutOfReach
}

const OtherPlayer: React.FC<PlayerProps> = props => {
  useVideoAnswer(props.player.peerId, props.distance || 0)

  return <Player {...props} />
}

export default OtherPlayer
