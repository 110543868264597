import React from 'react'
import { useVideoCall } from '../../hooks/useVideoCall'
import StreamAvatar from './StreamAvatar'

interface Props {
  peerId: string
  faceIndex: number
  x: number
  y: number
  distance: number
  size: number
  color: string
}

const PlayerVideoStream: React.FC<Props> = ({
  peerId,
  faceIndex,
  distance,
  ...props
}) => {
  const stream = useVideoCall(peerId, faceIndex, distance)

  // Position avatars
  return <StreamAvatar stream={stream || new MediaStream()} {...props} />
}

export default PlayerVideoStream
