import styled from '@emotion/styled'
import React, { memo } from 'react'

type Props = {
  x: number
  y: number
}

const StyledButton = styled.button<Props>`
  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    height: 30px;
    font-size: 23px;
    line-height: 23px;
    color: #222;

    /* Force centering with flex */
    &::before,
    &::after {
      content: '';
      flex: 1;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`

const ZoomButton: React.FC<Props & JSX.IntrinsicElements['button']> = ({
  children,
  ...props
}) => (
  <StyledButton {...props}>
    <span>{children}</span>
  </StyledButton>
)

export default memo(ZoomButton)
