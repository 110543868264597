import { intersect } from 'mathjs'
import { PlayerState } from '../store/player'
import { getDistance } from './getDistance'

export interface Background {
  width: number
  height: number
  scale: number
  spawnArea: number[][] // topLeft, bottomRight
  walls: Polygon[]
}

export type Walls = Polygon[]
export type Polygon = Point[]
export type Point = number[]

// Counterclockwise
export const ccw = (A: Point, B: Point, C: Point) =>
  (C[1] - A[1]) * (B[0] - A[0]) > (B[1] - A[1]) * (C[0] - A[0])

// Return true if line segments AB and CD intersect
// https://stackoverflow.com/questions/3838329/how-can-i-check-if-two-segments-intersect
export const doIntersect = (A: Point, B: Point, C: Point, D: Point) =>
  ccw(A, C, D) !== ccw(B, C, D) && ccw(A, B, C) !== ccw(A, B, D)

// Length of a vector
export function length(A: Point) {
  return Math.sqrt(A[0] * A[0] + A[1] * A[1])
}

// Normalize and scale vector
export function normalize(A: Point, scale = 1) {
  const distance = length(A)
  return [(A[0] * scale) / distance, (A[1] * scale) / distance]
}

export function avoidWalls(
  startPoint: Point,
  targetPoint: Point,
  walls: Polygon[],
  minDistance: number
): Point {
  const extendRadius = normalize(
    [targetPoint[0] - startPoint[0], targetPoint[1] - startPoint[1]],
    minDistance
  )
  const endPoint = [
    targetPoint[0] + extendRadius[0],
    targetPoint[1] + extendRadius[1]
  ]
  let shift = [0, 0]

  for (const polygon of walls) {
    for (let i = 0; i < polygon.length; i++) {
      const point1 = polygon[i]
      const point2 = polygon[i === polygon.length - 1 ? 0 : i + 1]
      if (doIntersect(point1, point2, startPoint, endPoint)) {
        // Get intersection point
        const point = intersect(point1, point2, startPoint, endPoint) as Point
        if (point) {
          // Get orthogonal vector
          let orthoVector = [point1[1] - point2[1], point2[0] - point1[0]]
          /*eslint-disable */
          if (
            orthoVector[0] === 0
              ? orthoVector[1] > 0 === extendRadius[1] > 0
              : orthoVector[0] > 0 === extendRadius[0] > 0
          ) {
            orthoVector[0] *= -1
            orthoVector[1] *= -1
          }
          /*eslint-enable */
          orthoVector = normalize(orthoVector, minDistance)

          // Assign intersection point to (x, y)
          // Add a margin to avoid being into the wall
          endPoint[0] = point[0]
          endPoint[1] = point[1]
          shift = orthoVector
        }
      }
    }
  }

  return shift[0] || shift[1]
    ? [endPoint[0] + shift[0], endPoint[1] + shift[1]]
    : targetPoint
}

export function avoidOtherPlayers(
  targetPoint: Point,
  peerId: string,
  players: PlayerState[],
  minDistance: number
): Point {
  let [x, y] = targetPoint
  let changed = true
  let iterations = 0
  while (changed && iterations < 10) {
    changed = false
    for (const player of players) {
      if (player.peerId === peerId) continue
      const distance = getDistance(player.x, player.y, x, y)
      if (distance < minDistance) {
        x = player.x + ((x - player.x) * minDistance) / distance
        y = player.y + ((y - player.y) * minDistance) / distance
        changed = true
        iterations++
      }
    }
  }
  return [x, y]
}
