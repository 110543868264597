import { useCallback, useEffect, useRef } from 'react'
import store from '../store'

export default function useScenePosition(
  positionX: number,
  positionY: number,
  scale: number,
  setTransform: (
    x: number,
    y: number,
    scale: number,
    speed?: number,
    type?: string
  ) => void
) {
  const transformRef = useRef<{ x: number; y: number; scale: number }>({
    x: positionX,
    y: positionY,
    scale
  })

  useEffect(() => {
    transformRef.current = {
      x: positionX,
      y: positionY,
      scale
    }
  }, [positionX, positionY, scale])

  const getWorldPosition = useCallback(
    (clientX: number, clientY: number) => ({
      x: (clientX - transformRef.current.x) / transformRef.current.scale,
      y: (clientY - transformRef.current.y) / transformRef.current.scale
    }),
    []
  )

  const getClientPosition = useCallback(
    (x: number, y: number) => ({
      clientX: x * transformRef.current.scale + transformRef.current.x,
      clientY: y * transformRef.current.scale + transformRef.current.y
    }),
    []
  )

  const centerOnPlayer = useCallback(
    (onlyIfOnBorder?: boolean) => {
      const { x, y } = store.getState().player

      if (onlyIfOnBorder) {
        const position = getClientPosition(x, y)
        if (
          position.clientX > window.innerWidth / 8 &&
          position.clientX < (window.innerWidth * 7) / 8 &&
          position.clientY > window.innerHeight / 8 &&
          position.clientY < (window.innerHeight * 7) / 8
        ) {
          return
        }
      }

      setTransform(
        -x * transformRef.current.scale + window.innerWidth / 2,
        -y * transformRef.current.scale + window.innerHeight / 2,
        transformRef.current.scale
      )
    },
    [getClientPosition, setTransform]
  )

  return {
    getWorldPosition,
    getClientPosition,
    centerOnPlayer
  }
}
