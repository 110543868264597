import store from '../../store'
import { unlock } from './unlock'

let audioElement: HTMLAudioElement | undefined
let audioContext: AudioContext | undefined
let analyserNode: AnalyserNode | undefined
let mainGainNode: GainNode | undefined
let toDestinationNode: AudioNode | undefined // No to connect to destination
let destinationNode: MediaStreamAudioDestinationNode | undefined

async function init() {
  if (audioContext) return
  // Setup audio context
  audioContext = new (window.AudioContext ||
    (window as any).webkitAudioContext)()
  analyserNode = audioContext.createAnalyser()
  mainGainNode = audioContext.createGain()

  // Cut frequencies higher than human voice
  const filterNode = audioContext.createBiquadFilter()
  filterNode.type = 'lowpass'
  filterNode.frequency.setValueAtTime(3000, audioContext.currentTime)
  filterNode.Q.value = 0.7 // Sharp ramp, no gain peak

  // Connect nodes
  mainGainNode.connect(filterNode)
  filterNode.connect(analyserNode)
  toDestinationNode = filterNode

  // Change speaker if needed and possible
  // Using an Audio element
  const speakerId = store.getState().settings.audiooutput
  if (speakerId && canSwitchSpeaker()) {
    attachAudioElement(speakerId)
  }

  if (!audioElement) {
    // Using default destination
    toDestinationNode.connect(audioContext.destination)
  }

  unlock(audioContext)
}

init()

export function getAudioAnalyser() {
  return analyserNode
}

export function canSwitchSpeaker(): boolean {
  return (
    window.HTMLMediaElement && 'sinkId' in window.HTMLMediaElement.prototype
  )
}

async function attachAudioElement(speakerId: string) {
  if (!audioContext || !toDestinationNode || audioElement) return
  audioElement = new Audio()
  try {
    await (audioElement as any).setSinkId(speakerId)
    destinationNode = audioContext.createMediaStreamDestination()
    audioElement.srcObject = destinationNode.stream
    audioElement.play()
    toDestinationNode.connect(destinationNode)
  } catch (error) {
    console.error(`Error setting Audio Output to ${speakerId}`, error)
    audioElement = undefined
  }
}

export async function changeAudioOutput(speakerId: string) {
  if (!canSwitchSpeaker()) return
  if (!audioElement) {
    return attachAudioElement(speakerId)
  }
  try {
    await (audioElement as any).setSinkId(speakerId)
  } catch (error) {
    console.error(`Error setting Audio Output to ${speakerId}`, error)
  }
}

export function addSpeakerSource(stream: MediaStream): GainNode | undefined {
  if (!audioContext || !mainGainNode) return

  const sourceNode = audioContext.createMediaStreamSource(stream)
  const gainNode = audioContext.createGain()

  sourceNode.connect(gainNode)
  gainNode.connect(mainGainNode)
  return gainNode
}
