import settings from '../../settings'

export function getVolumeFromDistance(distance: number) {
  // 1 --- 1 (loud) --- 0 (muted) --- 0
  if (distance > settings.audio.distanceMuted) return 0
  if (distance < settings.audio.distanceLoud) return 1
  return (
    (settings.audio.distanceMuted - distance) /
    (settings.audio.distanceMuted - settings.audio.distanceLoud)
  )
}
