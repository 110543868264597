import adapter from 'webrtc-adapter'

// https://webrtc.github.io/samples/src/content/peerconnection/bandwidth/

interface RTCRtpParams extends RTCRtpParameters {
  encodings: Array<{ maxBitrate?: number }>
}

export async function setBandwidth(
  peerConnection: RTCPeerConnection,
  bandwidth: number
): Promise<void> {
  if (!peerConnection) return

  // In Chrome, use RTCRtpSender.setParameters to change bandwidth without
  // (local) renegotiation. Note that this will be within the envelope of
  // the initial maximum bandwidth negotiated via SDP.
  if (
    (adapter.browserDetails.browser === 'chrome' ||
      adapter.browserDetails.browser === 'safari' ||
      (adapter.browserDetails.browser === 'firefox' &&
        adapter.browserDetails.version! >= 64)) &&
    'RTCRtpSender' in window &&
    'setParameters' in window.RTCRtpSender.prototype
  ) {
    const sender = peerConnection.getSenders()[0]
    if (!sender) {
      throw new Error('[P2P] Error setting bandwidth. No sender.')
    }

    const parameters = sender.getParameters() as RTCRtpParams

    if (!parameters.encodings) parameters.encodings = []
    if (!parameters.encodings[0]) parameters.encodings[0] = {}

    if (bandwidth === Infinity) {
      // Unlimited bandwidth
      delete parameters.encodings[0].maxBitrate
    } else {
      parameters.encodings[0].maxBitrate = bandwidth * 1000
    }
    return sender.setParameters(parameters)
  }

  // Fallback to the SDP munging with local renegotiation way of limiting
  // the bandwidth.
  const offer = await peerConnection.createOffer()
  await peerConnection.setLocalDescription(offer)
  if (peerConnection.remoteDescription) {
    const desc = {
      type: peerConnection.remoteDescription.type,
      sdp:
        bandwidth === Infinity
          ? removeBandwidthRestriction(peerConnection.remoteDescription.sdp)
          : updateBandwidthRestriction(
              peerConnection.remoteDescription.sdp,
              bandwidth
            )
    }
    return peerConnection.setRemoteDescription(desc)
  }
}

function updateBandwidthRestriction(sdp: string, bandwidth: number) {
  let modifier = 'AS'
  if (adapter.browserDetails.browser === 'firefox') {
    bandwidth = (bandwidth >>> 0) * 1000
    modifier = 'TIAS'
  }
  if (sdp.indexOf('b=' + modifier + ':') === -1) {
    // insert b= after c= line.
    sdp = sdp.replace(
      /c=IN (.*)\r\n/,
      'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n'
    )
  } else {
    sdp = sdp.replace(
      new RegExp('b=' + modifier + ':.*\r\n'),
      'b=' + modifier + ':' + bandwidth + '\r\n'
    )
  }
  return sdp
}

function removeBandwidthRestriction(sdp: string) {
  return sdp.replace(/b=AS:.*\r\n/, '').replace(/b=TIAS:.*\r\n/, '')
}
