import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DevicesSelect from './DevicesSelect'

const Panel = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  max-width: 500px;
  background: rgba(50, 50, 50, 0.9);
  color: white;
  border-radius: 5px;
  padding: 10px;
  font-size: 0.9rem;

  &:hover {
    background: rgba(70, 70, 70, 0.9);
  }
  &.closed {
    cursor: pointer;
  }

  h1 {
    font-size: 1.1em;
    font-weight: normal;
    margin: 0;
  }
  &.open h1 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .close {
    cursor: pointer;
    float: right;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3px 0 0 0;
    span:first-of-type {
      padding-right: 10px;
    }
  }

  input,
  select {
    background: rgba(255, 255, 255, 0.2);
    color: white;
    padding: 5px;
    border: none;
    width: 250px;
  }
  option {
    background: #5a5a5a;
    color: white;
  }
`

const Settings: React.FC = () => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()

  return (
    <Panel
      onClick={() => !show && setShow(true)}
      className={show ? 'open' : 'closed'}
    >
      <h1>
        {t('settings.title')}
        {show && (
          <span onClick={() => setShow(false)} className="close">
            ✖
          </span>
        )}
      </h1>
      {show && <DevicesSelect />}
    </Panel>
  )
}

export default Settings
