import Peer from 'peerjs'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { addCallHandler, CallHandler, removeCallHandler } from '../network/p2p'
import { setBandwidth } from '../network/p2p/bandwidth'
import { CallMetadata } from '../network/p2p/interfaces'
import settings from '../settings'
import store, { RootState } from '../store'
import { getVideoBandwidthByDistance } from '../utils/getVideoBandwidthByDistance'
import useIsMounted from './useIsMounted'

interface AnswerCall {
  call: Peer.MediaConnection
  bandwidthTimeout: number
  bandwidthFirstSet: boolean
}

async function changeBandwidth(
  call: Peer.MediaConnection,
  bandwidth: number,
  retry?: () => void
): Promise<boolean> {
  try {
    if (settings.p2p.debug) {
      console.log(
        '[P2P] Trying to change video bandwidth',
        call.peer,
        bandwidth
      )
    }

    await setBandwidth(call.peerConnection, bandwidth)

    if (settings.p2p.debug) {
      console.log('[P2P] Video bandwidth changed', call.peer, bandwidth)
    }
    return true
  } catch {
    if (settings.p2p.debug) {
      console.log('[P2P] Error changing video bandwidth', call.peer, bandwidth)
    }
    if (retry) retry()
  }
  return false
}

export function useVideoAnswer(peerId: string, distance: number): void {
  const callsRef = useRef<Record<number | string, AnswerCall>>({})
  const bandwidth = useMemo(() => getVideoBandwidthByDistance(distance), [
    distance
  ])
  const bandwidthRef = useRef<number>(bandwidth)
  const active =
    useSelector((state: RootState) => state.streams.videos.length !== 0) &&
    bandwidth !== 0
  const isMounted = useIsMounted()

  const closeCall = useCallback((faceIndex: number | string) => {
    const answerCall = callsRef.current[faceIndex]
    if (!answerCall) return
    answerCall.call.close()
    window.clearTimeout(answerCall.bandwidthTimeout)
    delete callsRef.current[faceIndex]
  }, [])

  const closeCalls = useCallback(() => {
    for (const faceIndex of Object.keys(callsRef.current)) {
      closeCall(faceIndex)
    }
  }, [closeCall])

  useEffect(() => {
    if (!active) return
    const calls = callsRef.current

    const handleCall: CallHandler = call => {
      const { audio, faceIndex } = call.metadata as CallMetadata
      if (call.peer !== peerId || audio) return false
      if (typeof faceIndex === 'undefined') {
        console.error('[P2P] faceIndex not found in call metadata', call)
        call.close()
        return true
      }

      // Get stream from store
      const stream = store.getState().streams.videos[faceIndex]
      if (!stream) {
        call.close()
        return true
      }

      // Stream back webcam video
      call.answer(stream)

      calls[faceIndex] = {
        call,
        bandwidthTimeout: 0,
        bandwidthFirstSet: false
      }

      // Set bandwidth limit
      const tryChangeBandwidth = () => {
        const answerCall = calls[faceIndex]
        if (!answerCall) return
        window.clearTimeout(calls[faceIndex].bandwidthTimeout)
        answerCall.bandwidthTimeout = window.setTimeout(() => {
          if (!isMounted()) return
          const answerCall = calls[faceIndex]
          if (answerCall?.call?.open) {
            changeBandwidth(
              call,
              bandwidthRef.current,
              tryChangeBandwidth
            ).then(success => {
              answerCall.bandwidthFirstSet = success
            })
          }
        }, 1000)
      }
      tryChangeBandwidth()

      if (settings.p2p.debug) {
        console.log('[P2P] Answering video call with stream', call.peer, stream)
      }

      // Handle error / disconnection
      call.on('error', error => {
        if (!isMounted()) return
        closeCall(faceIndex)
        if (settings.p2p.debug) {
          console.warn('[P2P] Call video error (receiver)', error)
        }
      })

      return true
    }

    addCallHandler(handleCall)
    return () => {
      removeCallHandler(handleCall)
      closeCalls()
    }
  }, [peerId, active, isMounted, closeCall, closeCalls])

  // Change bandwidth limit
  useEffect(() => {
    bandwidthRef.current = bandwidth
    for (const answerCall of Object.values(callsRef.current)) {
      if (answerCall.bandwidthFirstSet) {
        window.clearTimeout(answerCall.bandwidthTimeout)
        try {
          changeBandwidth(answerCall.call, bandwidth)
        } catch {}
      }
    }
  }, [bandwidth])
}
