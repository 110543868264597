import styled from '@emotion/styled'
import React from 'react'
import { TransformWrapper } from 'react-zoom-pan-pinch'
import { PropsList } from 'react-zoom-pan-pinch/dist/store/interfaces/propsInterface'
import SceneWrapper from './SceneWrapper'
import { TransformWrapperHandlerProps } from './transformInterfaces'

const defaultScale = 0.5

const transformOptions: PropsList['options'] = {
  minScale: 0.3,
  maxScale: 2,
  limitToBounds: false
}

const panOptions: PropsList['pan'] = {
  velocity: true,
  disableOnTarget: ['no-pan']
} as any

const wheelOptions: PropsList['wheel'] = {
  step: 30
}

const doubleClickOptions: PropsList['doubleClick'] = {
  disabled: true
}

const SceneContainer = styled.div`
  .react-transform-component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Scene: React.FC = () => {
  return (
    <SceneContainer>
      <TransformWrapper
        defaultScale={defaultScale}
        defaultPositionX={200}
        defaultPositionY={100}
        pan={panOptions}
        wheel={wheelOptions}
        options={transformOptions}
        doubleClick={doubleClickOptions}
      >
        {(props: TransformWrapperHandlerProps) => <SceneWrapper {...props} />}
      </TransformWrapper>
    </SceneContainer>
  )
}

export default Scene
