import {
  DtlsParameters,
  IceCandidate,
  IceParameters,
  MediaKind,
  RtpParameters
} from 'mediasoup-client/lib/types'
import socketio from 'socket.io-client'
import settings from '../settings'
import store, { actions } from '../store'
import { PlayerState } from '../store/player'

export enum TransportTypes {
  producer = 'producer',
  consumer = 'consumer'
}

export const io = socketio(settings.socketio.uri, settings.socketio.options)

export const emitAsync = function(event: string, payload?: any): Promise<any> {
  return new Promise((resolve, reject) => {
    // Apparently if we want to use a callback method we need it as 3rd argument even without args
    io.emit(event, payload != null ? payload : {}, (data: any) => {
      if (data.isError) {
        reject(data)
      } else {
        resolve(data)
      }
    })
  })
}

io.on('reconnect', () => {
  console.log('Socket reconnect event')

  const { room } = store.getState()
  if (room.id) {
    // TODO: If the server restarts its cache is cleaned. Reconnecting works correctly, but all server side data (such as name) are deleted
    // Maybe some "syncPlayer" to sync states?
    joinRoom(room.id)
    // store.dispatch(actions.room.createRoomTransports() as any)
  }
})

io.on('players', (players: PlayerState[]) =>
  store.dispatch(actions.room.setPlayers(players) as any)
)

io.on('name', ({ peerId, name }: { peerId: string; name: string }) =>
  store.dispatch(actions.room.setPlayerName(peerId, name) as any)
)

io.on('audio', ({ peerId, audio }: { peerId: string; audio: boolean }) =>
  store.dispatch(actions.room.setPlayerAudio(peerId, audio) as any)
)

io.on('faces', ({ peerId, faces }: { peerId: string; faces: number }) =>
  store.dispatch(actions.room.setPlayerFaces(peerId, faces) as any)
)

io.on('move', ({ peerId, x, y }: { peerId: string; x: number; y: number }) =>
  store.dispatch(actions.room.setPlayerPosition(peerId, x, y) as any)
)

export async function joinRoom(roomId: string) {
  const { peerId } = store.getState().player
  io.emit('join', roomId, peerId)
  store.dispatch(actions.room.setId(roomId) as any)
}

export async function createWebRTCTransport(
  transportType: TransportTypes
): Promise<{
  id: string
  iceParameters: IceParameters
  iceCandidates: IceCandidate[]
  dtlsParameters: DtlsParameters
}> {
  const transportParams = await emitAsync('createTransport', { transportType })
  return transportParams
}

export async function connectTransport(
  transportType: TransportTypes
): Promise<void> {
  console.log(`connect ${transportType} transport`)
  await emitAsync('connectTransport', { transportType })
  console.log(`transport ${transportType} connected`)
}

export async function produceMedia(
  kind: MediaKind,
  rtpParameters: RtpParameters
): Promise<string> {
  return emitAsync('produce', { kind, rtpParameters })
}

export async function consumePeerVideo(
  peerId: string
): Promise<{
  producerId: string
  id: string
  kind: MediaKind
  rtpParameters: RtpParameters
  appData: any
  producerPaused: boolean
}> {
  return emitAsync('consumePeerVideo', { peerId })
}
