import settings from '../../settings'
import { Box } from './interfaces'

const speed = 1 / settings.video.faceTransitionRatio

export function transitionBox(box: Box, toBox: Box) {
  box.x += (toBox.x - box.x) * speed
  box.y += (toBox.y - box.y) * speed
  box.width += (toBox.width - box.width) * speed
  box.height += (toBox.height - box.height) * speed
}

export function getDefaultBox(video: HTMLVideoElement): Box {
  const width = video.videoWidth
  const height = video.videoHeight
  const size = Math.min(width, height)
  return {
    height: size,
    width: size,
    x: width < height ? 0 : (width - height) / 2,
    y: width > height ? 0 : (height - width) / 2
  }
}
