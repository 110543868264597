import { useEffect, useState } from 'react'
import { approxEqual } from '../utils/approxEqual'

export function useMove(
  targetX: number,
  targetY: number
): { x: number; y: number; distance: number } {
  const [move, setMove] = useState(() => ({
    x: targetX,
    y: targetY,
    distance: 0
  }))

  useEffect(() => {
    const a = targetX - move.x
    const b = targetY - move.y
    const moveDistance = Math.sqrt(a * a + b * b)
    if (!approxEqual(moveDistance, 0)) {
      setMove({ x: targetX, y: targetY, distance: moveDistance })
    }
  }, [targetX, targetY, move.x, move.y])

  return move
}
